<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>

    <b-row match-height>
      <b-col md="3">
        <SecTodayDep />
      </b-col>
      <b-col md="3">
        <SecTodayWit />
      </b-col>
      <b-col md="3">
        <SecTodayBonus />
      </b-col>
      <b-col md="3">
        <SecTodayGame />
      </b-col>
      <b-col md="3">
        <SecTodayCashback />
      </b-col>
      <b-col md="3">
        <SecTodayCommission />
      </b-col>
      <b-col md="3">
        <SecTodayLottoCredit />
      </b-col>
      <b-col md="3">
        <SecTodayLottoAny />
      </b-col>
      <b-col md="6">
        <SecProfit />
      </b-col>
      <b-col md="6">
        <SecUsers />
      </b-col>
      <b-col md="12">
        <SecBanks />
      </b-col>
      <b-col md="12">
        <SecRanks />
      </b-col>
      <b-col md="6">
        <SecZean />
      </b-col>
      <b-col md="6">
        <SecZeanKR />
      </b-col>
      <b-col md="8">
        <SecDailysum :isLoading="reportLoading" :report-day="reportDay" :profit-days="profitDays" />
      </b-col>
      <b-col md="4">
        <SecMonthlysum :isLoading="reportLoading" :report-year="reportYear" />
      </b-col>
      <b-col md="8">
        <SecDailysumKR :isLoading="reportLoading" :report-day="reportDay" :profit-days="profitDays_kr" />
      </b-col>
      <b-col md="4">
        <SecMonthlysumKR :isLoading="reportLoading" :report-year="reportYear" />
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BIconController, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import DataTableView from './DataTable/view.vue'
import SecTodayDep from './sections/today-dep.vue'
import SecTodayWit from './sections/today-wit.vue'
import SecTodayBonus from './sections/today-bonus.vue'
import SecTodayGame from './sections/today-game.vue'
import SecTodayCashback from './sections/today-cashback.vue'
import SecTodayCommission from './sections/today-commission.vue'
import SecTodayLottoCredit from './sections/today-lotto-credit.vue'
import SecTodayLottoAny from './sections/today-lotto-any.vue'
import SecProfit from './sections/profit.vue'
import SecUsers from './sections/users.vue'
import SecBanks from './sections/banks.vue'
import SecRanks from './sections/ranks.vue'
import SecZean from './sections/zean.vue'
import SecZeanKR from './sections/zean-kr.vue'
import SecDailysum from './sections/dailysum.vue'
import SecDailysumKR from './sections/dailysum-kr.vue'
import SecMonthlysum from './sections/monthlysum.vue'
import SecMonthlysumKR from './sections/monthlysum-kr.vue'

// import SecTwo from './DataTable/sec2.vue'
// import SecTree from './DataTable/sec3.vue'
// import SecFour from './DataTable/sec4.vue'
// import Sec2Users from './componenets/sec2_users.vue'
// import Sec3Sum from './componenets/sec3_sum.vue'
// import Sec4Profit from './componenets/sec4_profit.vue'
// import Sec5Withdraws from './componenets/sec5_withdraws.vue'
// import Sec6Depwitsum from './componenets/sec6_depwitsum.vue'
// import Sec7Deposits from './componenets/sec7_deposits.vue'
// import Sec8Zean from './componenets/sec8_zean.vue'
// import Sec8ZeanKr from './componenets/sec8_zean_kr.vue'
// import Sec9Dailysum from './componenets/sec9_dailysum.vue'
// import Sec9DailysumTb from './componenets/sec9_dailysum_tb.vue'
// import Sec10Monthlysum from './componenets/sec10_monthlysum.vue'
// import Sec11DailysumKr from './componenets/sec11_dailysum_kr.vue'
// import Sec9DailysumTbKr from './componenets/sec9_dailysum_tb_kr.vue'
// import Sec12MonthlysumKr from './componenets/sec12_monthlysum_kr.vue'
// import Dep_rank from './componenets/dep_rank.vue'
// import With_rank from './componenets/with_rank.vue'

export default {
  components: {
    BIconController,
    BRow,
    BCol,
    BOverlay,
    // DataTableView,
    // Sec2Users,
    // Sec3Sum,
    // Sec4Profit,
    // // Sec5Withdraws,
    // Sec6Depwitsum,
    // // Sec7Deposits,
    // Sec8Zean,
    // Sec8ZeanKr,
    // Sec9Dailysum,
    // Sec9DailysumTb,
    // Sec9DailysumTbKr,
    // Sec10Monthlysum,
    // Sec11DailysumKr,
    // Sec12MonthlysumKr,
    // Dep_rank,
    // With_rank,
    SecTodayDep,
    SecTodayWit,
    SecTodayBonus,
    SecTodayGame,
    SecTodayCashback,
    SecTodayCommission,
    SecTodayLottoCredit,
    SecTodayLottoAny,
    SecProfit,
    SecUsers,
    SecBanks,
    SecRanks,
    SecZean,
    SecZeanKR,
    SecDailysum ,
    SecDailysumKR,
    SecMonthlysum ,
    SecMonthlysumKR,
    // SecOne,
    // SecTwo,
    // SecTree,
    // SecFour,
  },
  data() {
    return {
      isLoading: false,
      data: null,
      reportDay: [],
      reportYear: [],
      profitDays: '',
      agent: null,
      winLossAllSeries: [],
      datalotto: [],
      // รายการฝากวันนี้
      depSum: 0,
      depSumKr: 0,
      reportLoading: false
    }
  },
  computed: {
    // agent() {
    //   return store.state.app.agent
    // },
  },
  mounted() {
    this.getReportDay()
    // this.getData()
    // this.getAgent()
    // this.GetDataLotto()
    // this.getResource('TODAY_DEP');
  },
  methods: {
    GetDataLotto() {
      this.$http
        .get('/lottery/sumlotto')
        .then(response => {
          this.datalotto = response.data
        })
        .catch(error => console.log(error))
    },
    getAgent() {
      this.$http
        .get('/agent/show')
        .then(response => {
          this.agent = response.data
          // this.switch(response.data)
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    async getData() {
      // this.isLoading = true
      try {
        const { data } = await this.$http.get('/dashboard/index')

        console.log(data)
        this.data = data
        this.winLossAllSeries = [
          {
            name: 'ยอดเสีย',
            data: [
              data.winLossAll ? data.winLossAll.sport_winloss : 0,
              data.winLossAll ? data.winLossAll.slot_winloss : 0,
              data.winLossAll ? data.winLossAll.casino_winloss : 0,
              data.winLossAll ? data.winLossAll.poker_winloss : 0,
              data.winLossAll ? data.winLossAll.lotto_winloss : 0,
            ],
          },
        ]
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    getReportDay() {
      this.reportLoading = true;
      this.$http
        .get('dashboard/ReportDaysAndYears')
        .then(response => {
          this.reportDay = response.data.days
          this.profitDays = response.data.profitDay
          this.profitDays_kr = response.data.profitDay_kr
          this.reportYear = response.data.years
          console.log(response.data.days)
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.reportLoading = false;
        })
    },
  },
}
</script>

<style scoped>
.card {
  border-radius: 1rem;
}

.btn-success {
  box-shadow: rgba(0, 204, 136, 0.25) 0px 0px 10px 2px !important;
}

.btn-danger {
  box-shadow: rgba(230, 63, 89, 0.25) 0px 0px 10px 2px !important;
}
</style>
