<template>
  <b-overlay :show="isLoading">
    <div class="card p-1 rounded-lg">
      <div class="d-flex justify-content-between align-items-center">
        <p class="mb-0 text-hede">
          <i class="far fa-angle-double-up text-danger" /> รายการถอนวันนี้
        </p>

        <p class="mb-0">
          <span class="text-danger">
            {{ count.toLocaleString() }}
          </span>
          รายการ
        </p>
      </div>

      <div class="my-50">
        <small>ยอดเงิน (บาท)</small>
        <h3 class="mb-25 font-weight-bolder text-danger">
          {{ todayWit.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) }}
          <span style="font-size: 0.95rem">THB</span>
        </h3>
      </div>

      <hr>

      <div class="my-50">
        <small>ยอดเงิน (วอน)</small>
        <h3 class="mb-25 font-weight-bolder text-danger">
          {{ todayWitKR.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) }}
          <span style="font-size: 0.95rem">KRW</span>
        </h3>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BSpinner, BRow, BCol, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner, BRow, BCol, BOverlay,
  },
  data() {
    return {
      isLoading: false,
      todayWit: 0,
      todayWitKR: 0,
      count: 0,
    }
  },
  mounted() {
    this.getData('TODAY_WIT')
  },
  methods: {
    async getData(type) {
      this.isLoading = true
      try {
        const { data } = await this.$http.get(`/dashboard?type=${type}`)

        this.todayWit = data.todayWit
        this.todayWitKR = data.todayWitKR
        this.count = data.count
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
