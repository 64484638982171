<template>
  <div class="row">
    <div class="col-6">
      <b-overlay :show="isLoading">
        <b-card class="card-transaction h-100" style="border-radius: 15px;" no-body>
          <b-card-header>
            <b-card-title>รายการฝากสูงสุด 5 อันดับ (ไทย)</b-card-title>
            <feather-icon icon="MoreVerticalIcon" size="18" class="cursor-pointer" />
          </b-card-header>

          <b-card-body>
            <div v-for="(item, _id) in ranks.DepTop5" :key="_id" class="rank_list mb-1">
              <img src="/rank/5.webp" alt="rank" height="50">

              <div class="rank_detail ml-1">
                <h3 class="mb-0">
                  {{ item.username }}
                </h3>
                <small>จำนวน {{ item.amount.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }) }} บาท</small>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-overlay>
    </div>
    <div class="col-6">
      <b-overlay :show="isLoading">
        <b-card class="card-transaction h-100" style="border-radius: 15px;" no-body>
          <b-card-header>
            <b-card-title>รายการฝากสูงสุด 5 อันดับ (เกาหลี)</b-card-title>
            <feather-icon icon="MoreVerticalIcon" size="18" class="cursor-pointer" />
          </b-card-header>

          <b-card-body>
            <div v-for="(item, _id) in ranks.DepTop5KR" :key="_id" class="rank_list mb-1">
              <img src="/rank/5.webp" alt="rank" height="50">

              <div class="rank_detail ml-1">
                <h3 class="mb-0">
                  {{ item.username }}
                </h3>
                <small>จำนวน {{ item.amount.toLocaleString("en-US", {
        minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                  }) }} KRW</small>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BOverlay
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody, BOverlay
  },
  props: ['data'],
  data() {
    return {
      ranks: [],
      isLoading: false
    }
  },
  mounted() {
    this.getData('RANKS');
  },
  methods: {
    async getData(type) {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(`/dashboard?type=${type}`);

        this.ranks = data;
      } catch (error) {
        console.log(error);
      } finally {

        this.isLoading = false;
      }
    }
  }
}

</script>

<style lang="scss" scoped></style>
