<template>
  <b-overlay :show="isLoading">
    <div class="card p-1 rounded-lg">
      <div class="d-flex justify-content-between align-items-center">
        <p class="mb-0 text-hede">
          <i class="fas fa-stars text-primary" /> คืนยอดเสีย
        </p>

        <i class="far fa-ellipsis-v" />
      </div>

      <div class="my-50">
        <small>ยอดเงิน (บาท)</small>
        <h3 class="mb-25 font-weight-bolder text-primary">
          {{ sum.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }) }}
          <span style="font-size: 0.95rem">THB</span>
        </h3>
      </div>

      <hr>

      <div class="my-50">
        <small>ยอดเงิน (วอน)</small>
        <h3 class="mb-25 font-weight-bolder text-primary">
          {{ sumKR.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
          }) }}
          <span style="font-size: 0.95rem">KRW</span>
        </h3>
      </div>
    </div>
  </b-overlay>
</template>


<script>
import {
  BSpinner, BRow, BCol, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner, BRow, BCol, BOverlay,
  },
  data() {
    return {
      isLoading: false,
      sum: 0,
      sumKR: 0,
    }
  },
  mounted() {
    this.getData('TODAY_CASHBACK');
  },
  methods: {
    async getData(type) {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get(`/dashboard?type=${type}`);

        this.sum = data.sum;
        this.sumKR = data.sumKR;
      } catch (error) {
        console.log(error);
      } finally {

        this.isLoading = false;
      }
    }
  }
}
</script>