<template>
  <b-overlay :show="isLoading">
    <div class="card p-1 rounded-lg">
      <div class="d-flex justify-content-between align-items-center">
        <p class="mb-0 text-hede">
          <i class="fas fa-wreath text-info" /> รางวัลกงล้อ
        </p>

        <i class="far fa-ellipsis-v" />
      </div>

      <div class="my-50">
        <small>ยอดเงิน (บาท)</small>
        <h3 class="mb-25 font-weight-bolder text-info">
          {{ todayBonus.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
          <span style="font-size: 0.95rem">THB</span>
        </h3>
      </div>

      <hr>

      <div class="my-50">
        <small>ยอดเงิน (วอน)</small>
        <h3 class="mb-25 font-weight-bolder text-info">
          {{ todayBonusKR.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) }}
          <span style="font-size: 0.95rem">KRW</span>
        </h3>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BSpinner, BRow, BCol, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner, BRow, BCol, BOverlay,
  },
  data() {
    return {
      isLoading: false,
      todayBonus: 0,
      todayBonusKR: 0,
    }
  },
  mounted() {
    this.getData('TODAY_LUCKYWHEEL')
  },
  methods: {
    async getData(type) {
      this.isLoading = true
      try {
        const { data } = await this.$http.get(`/dashboard?type=${type}`)

        this.todayBonus = data.todayBonus
        this.todayBonusKR = data.todayBonusKR
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
